<template>
  <v-container fluid class="pa-0">
    <page-title
      :heading="$t('erp.lang_storageOverview') "
      :subheading="$t('erp.lang_storageOverview')"
      :icon="icon"
      :show-add-button="true"
      :permission-add="true"
    ></page-title>
    <div class="app-main__inner">
      <StorageOverView />
    </div>
  </v-container>
</template>

<script>
//components
import PageTitle from "../../../../Layout/Components/PageTitle.vue";
import StorageOverView from "../../../../components/erp/storage/storageoverview/StorageOverviewComponent";

export default {
  components: {
    PageTitle,
    StorageOverView
  },
  data: () => ({
    icon: "pe-7s-note2 icon-gradient bg-tempting-azure"
  })
};
</script>

<style>
</style>